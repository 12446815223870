<template>
  <div class="mains">
    <h3>网站简介</h3>
    <p>
      兰州市残疾人就业创业网络服务平台（下称“平台”）是根据《“十三五”加快残疾人小康进程规划纲要》精神，为满足全国范围内残疾人就业服务需求，更好的促进残疾人就业，由中国残疾人联合会搭建的残疾人就业互联网服务平台。平台是中国残疾人联合会开展 “互联网+残疾人就业”服务的主要方式，是满足经济社会发展新形势下残疾人就业需求的重要手段。
    </p>
    <p>
      平台具有服务渠道全面，服务内容广泛，服务对象多样的特点。平台服务渠道包括网站、APP、微信公众平台，基本覆盖了电脑端与移动端主要渠道；服务对象包括全国就业年龄段残疾人、企业、各级残联就业服务机构，提供包括残疾人在线求职、在线职业能力测评、职业技能培训、电商创业、互动社区，企业招聘，残联就业服务机构线上残疾人就业服务支持等各项服务。
    </p>
    <p>
      2017年底兰州市残疾人就业创业网络服务平台正式开展专业化运营，构建了以保障平台服务为核心的宣传推广、活动策划实施、新媒体运营、用户服务、资源整合等多方位的运营服务体系，以促进平台持续发挥促进残疾人就业服务的作用，保证平台服务效果。
    </p>
  </div>
</template>

<script>
export default {
name: "Introduction"
}
</script>

<style scoped lang="less">
.mains{
  width: 100%;
  h3{
    font-size: 20px;
    text-align: center;
    padding-bottom: 30px;
    letter-spacing:5px
  }
  p{
    font-size: 16px;
    text-indent: 2em;
    margin-bottom: 10px;
    line-height: 1.5em;
  }
}

</style>
